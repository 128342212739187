import React from "react";
import './privacy.css'

const PrivacyPolicy = () => {





    const currentDate = new Date();
  
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    const formattedDate = currentDate.toLocaleDateString('en-US', options);
  return (
   <div style={{margin:"20px 0px 0px 0px"}}>
     <div className="privacy-policy">
      <h1 style={{textAlign:"center"}}>Privacy Policy</h1>
      <p className="disclaimer">
        This Privacy Policy describes how Cloud Express Solutions collects, uses,
        shares, and protects information about you. By using our platform, you
        consent to the collection and use of your information as described in
        this policy.
      </p>

      <section>
        <h2>Information Collection</h2>
        <p>
          We collect various types of information in connection with the
          services we provide, including:
        </p>
        <ul>
          <li>
            **Personal Data**: Information you provide directly, such as your
            name, email address, phone number, and other similar contact data.
          </li>
          <li>
            **Usage Data**: Information about your interactions with our
            services, such as IP addresses, log files, and usage data.
          </li>
          <li>
            **Location Data**: Information about your device's location if you
            have enabled this feature.
          </li>
        </ul>
      </section>

      <section>
        <h2>Use of Information</h2>
        <p>
          We use the information we collect to:
        </p>
        <ul>
          <li>Provide and improve our services</li>
          <li>Communicate with you about your account or transactions</li>
          <li>Send you information about features and enhancements</li>
          <li>Protect against, identify, and prevent fraud and other
            unlawful activity</li>
          <li>Comply with legal obligations</li>
        </ul>
        <p>
          We may also use your data in an aggregated and anonymized format for
          internal purposes, such as data analysis and research.
        </p>
      </section>

      <section>
        <h2>Cookies</h2>
        <p>
          We use data collection devices such as "cookies" on certain pages of
          the Platform to help analyze our web page flow, measure promotional
          effectiveness, and promote trust and safety. "Cookies" are small files
          placed on your hard drive that assist us in providing our services.
          Cookies do not contain any of your personal data. We offer certain
          features that are only available through the use of a "cookie". We
          also use cookies to allow you to enter your password less frequently
          during a session. Cookies can also help us provide information that is
          targeted to your interests. Most cookies are "session cookies," meaning
          that they are automatically deleted from your hard drive at the end of
          a session. You are always free to decline our cookies if your browser
          permits, although in that case you may not be able to use certain
          features on the Platform and you may be required to re-enter your
          password more frequently during a session. Additionally, you may
          encounter "cookies" or other similar devices on certain pages of the
          Platform that are placed by third parties. We do not control the use
          of cookies by third parties. If you choose to buy on the Platform, we
          collect information about your buying behaviour. If you transact with
          us, we collect some additional information, such as a billing address,
          a credit/debit card number and a credit/debit card expiration date
          and/ or other payment instrument details and tracking information from
          cheques or money orders.
        </p>
      </section>

      <section>
        <h2>Sharing</h2>
        <p>
          We may share your personal data internally within Cloud Express Solutions
          entities, our other corporate entities, and affiliates to provide you
          access to the services and products offered by them. We may disclose
          personal data to third parties. This disclosure may be required for
          us to provide you access to our services, to comply with our legal
          obligations, to enforce our User Agreement, to facilitate our
          marketing and advertising activities, or to prevent, detect, mitigate,
          and investigate fraudulent or illegal activities related to our
          services. We do not disclose your personal data to third parties for
          their marketing and advertising purposes without your explicit
          consent. We may disclose personal data, if required to do so by law or
          in the good faith belief that such disclosure is reasonably necessary
          to respond to subpoenas, court orders, or other legal process. We may
          disclose personal data to law enforcement offices, third party rights
          owners, or others in the good faith belief that such disclosure is
          reasonably necessary to enforce our terms or privacy policy; respond
          to claims that an advertisement, posting or other content violates the
          rights of a third party; or protect the rights, property or personal
          safety of our users or the general public. We and our affiliates will
          share / sell some or all of your personal data with another business
          entity should we (or our assets) plan to merge with, or be acquired by
          that business entity, or re-organisation, amalgamation, restructuring
          of business. Should such a transaction occur that other business
          entity (or the new combined entity) will be required to follow this
          privacy policy with respect to your personal data.
        </p>
      </section>

      <section>
        <h2>Links to Other Sites</h2>
        <p>
          Our Platform links to other websites that may collect personal data
          about you. Cloud Express Solutions is not responsible for the privacy practices or the
          content of those linked websites.
        </p>
      </section>

      <section>
        <h2>Security Precautions</h2>
        <p>
          To protect your personal data from unauthorised access or disclosure,
          loss or misuse we adopt reasonable security practices and procedures,
          in line with international standards ISO/IEC 27001:2013 and applicable
          law. Whenever you access your account information, we offer the use of
          a secure server. Once your personal data is in our possession we
          adhere to our security guidelines to protect it against unauthorized
          access. By using the Platform, you accept the inherent security
          implications of data transmission over the internet and the World Wide
          Web which cannot always be guaranteed as completely secure, and
          therefore, there would always remain certain inherent risks regarding
          use of the Platform. Users are responsible for ensuring the protection
          of login and password records for their account.
        </p>
      </section>

      <section>
        <h2>Choice/Opt-Out</h2>
        <p>
          We provide all users with the opportunity to opt-out of receiving
          non-essential (promotional, marketing-related) communications from us
          after setting up an account. If you want to remove your contact
          information from all Cloud Express Solutions lists and newsletters, please
          unsubscribe via the link on the email.
        </p>
      </section>

      <section>
        <h2>Advertisements</h2>
        <p>
          We use third-party advertising companies to serve ads when you visit
          our Platform. These companies may use information (not including your
          name, address, email address, or telephone number) about your visits
          to this Platform and other websites in order to provide advertisements
          about goods and services of interest to you. Cloud Express Solutions authorizes third
          parties to serve advertisements and links to other websites on
          Cloud Express Solutions. These third parties use technology to send, directly to your
          browser, the advertisements and links that appear on the Platform.
          They automatically receive your IP address when this happens. They may
          also use other technologies (such as cookies, JavaScript, or web
          beacons) to measure the effectiveness of their advertisements and to
          personalize advertising content. Cloud Express Solutions does not have access to or
          control over cookies or other features that advertisers and third
          parties may use, and the information practices of these advertisers
          and third-party websites are not covered by this Privacy Policy.
          Please contact them directly for more information about their privacy
          practices.
        </p>
      </section>

      <section>
        <h2>Use of Children Information</h2>
        <p>
          Use of our Platform is available only to persons who can form a
          legally binding contract under the Indian Contract Act, 1872. We do
          not knowingly solicit or collect personal data from children under the
          age of 18 years. If you are under 18 years of age, you may use/access
          the Platform only with the involvement of a parent or guardian. In the
          event that we learn that we have collected personal data from a child
          under age 18 without verification of parental consent, we will delete
          that information at the earliest.
        </p>
      </section>

      <section>
        <h2>Data Deletion and Retention</h2>
        <p>
          You have an option to delete your account by visiting your Profile and
          Settings on your mobile application, this action would result in you
          losing all information related to your account. We will keep your
          personal data only as long as we need it for legitimate business
          purposes (as set out in this Privacy Policy) and as required by
          applicable law. We will take reasonable steps to securely destroy such
          information when it is no longer needed.
        </p>
      </section>

      <section>
        <h2>Your Consent</h2>
        <p>
          By visiting our Platform or by providing your information, you consent
          to the collection, use, storage, disclosure, and otherwise processing
          of your information (including sensitive personal information) on the
          Platform in accordance with this Privacy Policy. If you disclose to us
          any personal data relating to other people, you represent that you
          have the authority to do so and to permit us to use the information in
          accordance with this Privacy Policy. You, while providing your
          personal data over the Platform or any partner platforms or
          establishments, consent to us (including our other corporate entities,
          affiliates, lending partners, technology partners, marketing channels,
          business partners, and other third parties) to contact you through
          SMS, instant messaging apps, call and/or e-mail for the purposes
          specified in this Privacy Policy.
        </p>
      </section>

      <section>
        <h2>Policy Changes</h2>
        <p>
          We may update this Privacy Policy to reflect changes to our information
          practices. If we make any material changes, we will notify you by
          email (sent to the email address specified in your account) or by
          means of a notice on this Platform prior to the change becoming
          effective. We encourage you to periodically review this page for the
          latest information on our privacy practices. By visiting our Platform
          or by providing your information, you consent to the collection, use,
          storage, disclosure and otherwise processing of your information on
          the Platform in accordance with this Privacy Policy.
        </p>
      </section>

      <section>
        <h2>Grievance Officer</h2>
        <p>
          In accordance with Information Technology Act 2000 and rules made
          there under, the name and contact details of the Grievance Officer are
          provided below:
        </p>
        <p>
          Grievance Officer: Mr. Sai Kumar
          <br />
          Cloud Express Solutions Private Limited,
          <br />
          No: 81, Kothanur Dinne Main Road, 
          <br />
          JP Nagar 8th Phase,
          <br />
          Bangalore - 560078
          <br />
          Phone: +91 8618525743
          <br />
          Email: sai@cloudexpresssolutions.com
          <br />
          Time: Mon - Sat (9:00 - 18:00)
        </p>
      </section>
      <section>
      
        <p>
          This Privacy Policy was last updated on {formattedDate}.
        </p>
      </section>
    </div>
   </div>
  );
};

export default PrivacyPolicy;
