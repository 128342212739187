import React from 'react'
import './term.css'

export default function TermsandConditions() {
  return (
    <div style={{margin:"20px 0px 0px 0px"}}>
    <div class="container">
        <h1 className='terms'>Terms and Conditions</h1>
        <p>
            Welcome to Cloud Express Solutions. This page details a limited period Offer provided by our participating sellers redeemable through our Cloud Express Solutions Marketplace Platform only in the mobile application. Please read these terms and conditions carefully before participating. You should understand that by participating in the Offer and using our Platform, you agree to be bound by these terms and conditions including Terms of Use and Privacy Policy of the Platform. Please understand that if you refuse to accept these terms and conditions, you will not be able to access the offers provided under our Platform.
        </p>

        <h1 className='terms'>General Terms & Conditions</h1>

        <div style={{margin:"0px 0px 0px 15px"}}>
        <ol class="terms-list">
            <li>The Offer is open to only end consumers and not resellers.</li>
            <li>Sellers are running this Offer on the Cloud Express Solutions Platform.</li>
            <li>Offer benefits/Rewards shall not be settled in cash by Cloud Express Solutions/Sellers and are not transferable.</li>
            <li>Cloud Express Solutions/Sellers reserve the right to terminate, withdraw, suspend, amend, extend, modify, add, or remove portions of this Offer, at any time without any prior written notice.</li>
            <li>You are not bound in any way to participate in this Offer. Any participation is voluntary and the Offer is being made purely on a best-effort basis.</li>
            <li>All decisions pertaining to the selection of the participants for the Rewards under this Offer are at the sole discretion of Cloud Express Solutions/Seller based on a metric that is subject to change without notice.</li>
            <li>Cloud Express Solutions/Seller reserves the right to disqualify You from the benefits of this Offer if any fraudulent activity is identified as being carried out for the purpose of availing the benefits under the said Offer.</li>
            <li>The offer is subject to the serviceability of the area pin code.</li>
            <li>The Offer shall be subject to force majeure events and on the occurrence of such an event, the Offer may be withdrawn at the discretion of Cloud Express Solutions/Sellers.</li>
            <li>Cloud Express Solutions/Sellers shall not be responsible for any loss, injury, or any other liability arising due to participation by any person in this Offer.</li>
            <li>This Offer is subject to the prevailing laws of India.</li>
            <li>Cloud Express Solutions does not endorse the Rewards being offered under this Offer and will not accept any liability pertaining to the benefits of the Offer.</li>
            <li>Sellers are responsible for the fulfillment of the Offer. Any queries/complaints or disputes regarding the fulfillment of the Offer must be directed towards the Sellers. Cloud Express Solutions shall not entertain or be responsible for any queries/complaints regarding the fulfillment of the Offer.</li>
            <li>You hereby agree to indemnify and keep Cloud Express Solutions/Sellers harmless against all damages, liabilities, costs, expenses, claims, suits, and proceedings (including reasonable attorney’s fees) that may be suffered by Cloud Express Solutions as a consequence of (i) violation of the terms of this T&C by You; (ii) violation of applicable laws; (iii) any action or inaction resulting in willful misconduct or negligence on Your part.</li>
            <li>This T&C shall be governed in accordance with the applicable laws in India. Courts at Bangalore shall have the exclusive jurisdiction to settle any dispute that may arise under this T&C.</li>
            <li>Any query/claims regarding this Offer will be entertained only till the 10th of July, 2024. Post such date and time Cloud Express Solutions/Sellers will not entertain any correspondence or communication regarding this Offer from any persons.</li>
            <li>This document is an electronic record in terms of the Information Technology Act, 2000 and the Rules there under as applicable and the amended provisions pertaining to electronic records in various statutes as amended by the Information Technology Act, 2000. This electronic record is generated by a computer system and does not require any physical or digital signatures.</li>
        </ol>
        </div>



        <h1 className='terms'>Other Terms and Conditions</h1>

        <div style={{margin:"0px 0px 0px 15px"}}>
        <ol class="terms-list">
            <li>In case either of the products goes out of stock while browsing or in the cart, the offer is no longer applicable, and the user won’t be eligible for a free gift.</li>
            <li>Free gifts can’t be added to the bag without adding the parent product/base product to the bag.</li>
            <li>Free gifts can’t be checked out of the cart without the base style.</li>
            <li>In case of a return/exchange of the main product, free gifts should also be returned by the customer.</li>
            <li>No exchange or return is allowed for free gifts.</li>
            <li>Free Gift is a promotional product; no refund/reimbursement is available for damaged products received.</li>
            <li>This Offer is valid only in India.</li>
            <li>The Offer is open only to end consumers and not the resellers of the Eligible Product(s).</li>
            <li>Customers are not bound in any way to participate in this Offer. Any participation is voluntary, and the Offer is being made purely on a best-effort basis.</li>
            <li>Cloud Express Solutions, in its sole discretion, reserves the right to disqualify You from the Offer and/or the benefits of this Offer if any fraudulent activity is identified as being carried out for the purpose of availing the benefits under the said Offer.</li>
            <li>The Offer shall be subject to force majeure events and on occurrence of such an event, the Offer may be withdrawn at the discretion of the Brand and/or Cloud Express Solutions.</li>
        </ol>
        </div>

        <h2 className='terms'>Disclaimer</h2>
        <p>
            In the event the Cloud Express Solutions App is downloaded from Apple’s App Store, note that Apple’s App Store is only an app downloading platform and in no way, shape, or form, Apple is responsible/involved for any contest/offer being run on the Cloud Express Solutions App.
        </p>

    </div>
</div>

  )
}
