import React from 'react';
import AboutImage from '../../media/about.jpg'
import { FaStoreAlt } from "react-icons/fa";
import './about.css'


const AboutSection = () => {
 
  return (
    <div class="responsive-container-block bigContainer">
    <div class="responsive-container-block Container">
      <div class="imgContainer">
       
        <img class="mainImg" src={AboutImage}/>
      </div>
      <div class="responsive-container-block textSide">
        <p class="text-blk heading">
          About Us
        </p>
        <p class="text-blk subHeading">
        Welcome to Cloud Express Solutions - where innovation knows no bounds. At Cloud Express Solutions, we thrive on pushing boundaries, exploring new horizons, and embracing change.
        </p>
        <div class="responsive-cell-block wk-desk-6 wk-ipadp-6 wk-tab-12 wk-mobile-12">
          {/* <div class="cardImgContainer">
            <img class="cardImg" src="https://workik-widget-assets.s3.amazonaws.com/widget-assets/images/id2.svg"/>
          </div> */}
          <div class="cardText">
            <p class="text-blk cardHeading">
            Our Humble Beginnings.
            </p>
            <p class="text-blk cardSubHeading">
            From humble beginnings, we have grown into a dynamic force in the tech industry, driven by our relentless pursuit of excellence and our commitment to transforming ideas into reality. Our journey started with a simple vision to revolutionize the way technology impacts our lives. Together, we are shaping the future of technology, one breakthrough at a time. Join us on this exciting journey, and together, let's turn ideas into reality, and dreams into achievements.
            </p>
          </div>
        </div>
        <div class="responsive-cell-block wk-desk-6 wk-ipadp-6 wk-tab-12 wk-mobile-12">
          {/* <div class="cardImgContainer">
            <img class="cardImg"  src="https://workik-widget-assets.s3.amazonaws.com/widget-assets/images/id2.svg" width={100}/>
          </div> */}
          <div class="cardText">
            <p class="text-blk cardHeading">
            Our Vision
            </p>
            <p class="text-blk cardSubHeading">
            Armed with this vision and fueled by our team's collective expertise, we set out to create cutting-edge solutions that would redefine the boundaries of possibility. With every line of code we write, every design we craft, and every project we undertake, we strive to push the envelope and challenge the status quo.
            </p>
          </div>
        </div>
        <div class="responsive-cell-block wk-desk-6 wk-ipadp-6 wk-tab-12 wk-mobile-12">
          {/* <div class="cardImgContainer">
            <img class="cardImg" src="https://workik-widget-assets.s3.amazonaws.com/widget-assets/images/id2.svg"/>
          </div> */}
          <div class="cardText">
            <p class="text-blk cardHeading">
            How are we different?
            </p>
            <p class="text-blk cardSubHeading">
            What sets us apart is not just our technical prowess, but our unwavering dedication to our clients' success. We believe in building lasting partnerships based on trust, transparency, and mutual respect. Whether it's a small startup with big dreams or a multinational corporation with complex needs, we approach each project with the same level of enthusiasm and commitment, ensuring that we deliver results that exceed expectations every time.
            </p>
          </div>
        </div>
       
      </div>
     
    </div>
  </div>
  );
};

export default AboutSection;
