import logo from './logo.svg';
import React,{lazy,Suspense} from 'react';



// import GRIDHover from './Pages/grid'
import { Routes,Route } from 'react-router-dom';
import Terms from './Pages/Policies/termsandConditions'
import Privacy from './Pages/Policies/privacyandPolicy'
import Cancellation from './Pages/Policies/cancellation'
import AboutUs from './Pages/allInfo/about'
import ContactUs from './Pages/allInfo/contact'
import Pricing from './Pages/allInfo/pricing';
const GRIDHover = lazy(()=>import('./Pages/grid'))
function App() {
  return (
    <div>
      
       {/* <GRIDHover/> */}
       <Routes>
        <Route exact path = '/' element={<Suspense fallback={<p>Loading....</p>}><GRIDHover/></Suspense>}/>
        <Route  path = '/termsandConditions' element={<Terms/>}/>
        <Route  path = '/privacyandPolicy' element={<Privacy/>}/>
        <Route  path = '/cancellation' element={<Cancellation/>}/>
         
        <Route  path = '/about' element={<AboutUs/>}/>
        <Route  path = '/contact' element={<ContactUs/>}/>
        <Route  path = '/pricing' element={<Pricing/>}/>
         


       </Routes>
    </div>
  );
}

export default App;
