import React from 'react'
import './contact.css'
export default function Contact() {
  return (
    <div>
   <div class="container1">

<h1 class="brand" ><span style={{color:"#333"}}>Cloud Express Solutions</span></h1>

<div class="wrapper">


  <div class="company-info">
    <h3>Cloud Express Solutions</h3>

    <ul>
      <li>No: 81, Kothanur Dinne Main Road</li>
      <li> JP Nagar 8th Phase,Bangalore - 560078</li>
      <li> info@cloudexpresssolutions.com</li>
    </ul>
  </div>
 
  <div class="contact">
    <h3>E-mail Us</h3>

    <form id="contact-form">

      <p>
        <label>Name</label>
        <input type="text" name="name" id="name" required/>
      </p>

      <p>
        <label>Company</label>
        <input type="text" name="company" id="company"/>
      </p>

      <p>
        <label>E-mail Address</label>
        <input type="email" name="email" id="email" required/>
      </p>

      <p>
        <label>Phone Number</label>
        <input type="text" name="phone" id="phone"/>
      </p>

      <p class="full">
        <label>Message</label>
        <textarea name="message" rows="5" id="message"></textarea>
      </p>

      <p class="full">
        <button type="submit">Submit</button>
      </p>

    </form>

  </div>
 
</div>

</div>

  
  
    </div>
  
  )
}
