import React from 'react'
import './cancellation.css'
export default function Cancellation() {
  return (
   <div style={{margin:"20px 0px 0px 0px"}}>
     <div className="cancellation-policy">
    <h1>Cancellation & Refund Policy</h1>

    <details>
      <summary>What is Cloud Express Solutions Cancellation Policy?</summary>
      <p>
        You can now cancel an order when it is in packed/shipped status, as
        long as the cancel option is available on App/Website/M-site. This
        includes items purchased on sale also. Any amount paid will be
        credited into the same payment mode using which the payment was made.
      </p>
    </details>

    <details>
      <summary>Can I modify the shipping address of my order after it has been placed?</summary>
      <p>
        Yes, you can modify the shipping address of your order before we have
        processed (packed) it, by updating it under the 'change address'
        option which is available under the ‘My order’ section of the
        App/Website/M-site.
      </p>
    </details>

    <details>
      <summary>How do I cancel my Order?</summary>
      <p>
        Tap on the “My Orders” section under the main menu of your
        App/Website/M-site and then select the item or order you want to
        cancel.
      </p>
    </details>

    <details>
      <summary>I just cancelled my order. When will I receive my refund?</summary>
      <p>
        If you had selected Cash on Delivery, there is no amount to be
        refunded because you haven't paid for your order. For payments made
        via Credit Card, Debit Card, Net Banking, or Wallet, you will receive
        a refund into the source account within 7-10 days from the time of
        order cancellation. If payment was made by redeeming PhonePe wallet
        balance, then the refund will be instant post order cancellation,
        which can be later transferred into your bank account by contacting
        the PhonePe customer support team.
      </p>
    </details>

    <details>
      <summary>What is Instant Refunds?</summary>
      <p>
      Upon successful pickup of the return product at your doorstep, Cloud Express Solutions will instantly initiate the refund to your source account or chosen method of refund. Instant Refunds is not available in a few select pin codes and for all self ship returns.
      </p>
    </details>


   



    <details>
        <summary>How long would it take me to receive the refund of the returned product?</summary>
        <p>
          After the refund has been initiated by Cloud Express Solutions as per
          the Returns Policy, the refund amount is expected to reflect in the
          customer account as per the following timelines:
        </p>
        <ul>
          <li>NEFT - 1 to 3 business days post refund initiation</li>
          <li>Cloud Express Solutions Credit - Instant</li>
          <li>Online Refund – 7 to 10 days post refund initiation, depending on your bank partner</li>
          <li>PhonePe wallet – Instant</li>
        </ul>
        <p>
          Please note, Cloud Express Solutions initiates the refund upon
          successful return pickup, or after the returned item has reached us
          and quality check is successful. Therefore, the refund initiation time
          may vary by time taken by the courier partner to deliver the return to
          a Cloud Express Solutions warehouse. In case of any refund
          discrepancies, Cloud Express Solutions may at its sole discretion,
          request you to share with us a screenshot of your bank statement.
        </p>
        <p>
          <strong>Note:</strong> You can maintain up to five bank accounts for
          NEFT/IMPS refunds and a single bank account can be linked up to five
          Cloud Express Solutions accounts. If you wish to add a new bank
          account over and above, please delete an existing bank account.
        </p>
      </details>



      <details>
      <summary>How do I return multiple products from a single order?</summary>
      <p>
      If you are returning multiple products from a single order then, you will receive a separate Return ID via e-mail for each item. If you are self-shipping the products, you can ship all the products in a single shipment. Please mention the Return IDs for all the products on a single sheet of paper and place it inside the packet.If mode of return is Pickup, our courier person will pickup the products from the pickup address.
      </p>
    </details>
  </div>
   </div>
  )
}
